import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MenuIconComponent from "../../.reUseableComponents/Buttons/MenuIconComponent.js";
import useDateRangeHook from "../../.reUseableComponents/CustomHooks/useDateRangeHook.js";
import useDeleteConfirmation from "../../.reUseableComponents/CustomHooks/useDeleteConfirmation.js";
import { useSalePersonHook } from "../../.reUseableComponents/CustomHooks/useSalePersonHook.js";
import DataGridTabs from "../../.reUseableComponents/DataGridTabs/DataGridTabs.js";
import DeleteConfirmationModal from "../../.reUseableComponents/Modal/DeleteConfirmationModal.js";
import CustomReactDatePickerInputFilter from "../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import SearchInputAutoCompleteMultiple from "../../.reUseableComponents/TextField/SearchInputAutoCompleteMultiple";
import SelectComponent from "../../.reUseableComponents/TextField/SelectComponent.js";
import OrderList from "./ordersList/index.js";
import {
  DeleteOrder,
  ExcelExportOrders,
  GetActiveCarriersForSelection,
  GetAllCarrierTrackingStatusForSelection,
  GetAllFullFillmentStatusLookup,
  GetAllOrderTypeLookup,
  GetAllOrders,
  GetAllPaymentMethodLookup,
  GetAllPaymentStatusLookup,
  GetAllSalePersonForSelection,
  GetAllStationLookup,
  GetCarrierWayBillsByOrderNos,
  GetStoresForSelection,
  GetValidateDocumentSetting,
} from "../../api/AxiosInterceptors";
import { styleSheet } from "../../assets/styles/style";
import AddBillingModal from "../../components/modals/orderModals/AddBillingModal.js";
import BatchAssigntoCarrierModal from "../../components/modals/orderModals/BatchAssigntoCarrierModal";
import BatchOrderFulfillmentModal from "../../components/modals/orderModals/BatchOrderFulfillmentModal";
import BatchUpdateOrderStatusModal from "../../components/modals/orderModals/BatchUpdateOrderStatusModal";
import UtilityClass from "../../utilities/UtilityClass";
import {
  EnumAwbType,
  EnumCarrierAssign,
  EnumChangeFilterModelApiUrls,
  EnumFullFillmentStatus,
  EnumNavigateState,
  EnumOptions,
  EnumOrderType,
  EnumPaymentStatus,
} from "../../utilities/enum";
import initialStateFilter from "../../utilities/filterState";
import {
  ActionButtonCustom,
  downloadWayBillsByOrderNos,
  fetchMethod,
  handleDownloadPdf,
  useGetAllCountries,
  useGetBreakPoint,
  useGetNavigateState,
  useSetNavigateStateData,
} from "../../utilities/helpers/Helpers";
import {
  useGetAllCitiesByRegionIds,
  useGetAllRegionbyCountryIds,
} from "../../utilities/helpers/HelpersFilter.js";
import {
  FulfillableOrderIcon,
  RegularOrderIcon,
  UploadOrderIcon,
} from "../../utilities/helpers/SvgIcons.js";
import {
  SchemaTextField,
  addressSchemaEnum,
  useGetAddressSchema,
} from "../../utilities/helpers/addressSchema.js";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../utilities/toast";

const carrierAssignModel = [
  {
    id: 0,
    text: "Please select option",
  },
  {
    id: EnumCarrierAssign.UnAssigne,
    text: "UnAssign",
  },
  {
    id: EnumCarrierAssign.Assign,
    text: "Assign",
  },
];

const MAX_TAGS = 200;
const EnumOrderTabFilter = Object.freeze({
  All: "/orders-dashboard",
  Unfulfilled: "/orders-dashboard/Unfullfilled",
  Fullfilled: "/orders-dashboard/fullfilled",
  Regular: "/orders-dashboard/regular",
  FullFillable: "/orders-dashboard/fullFillable",
  Unpaid: "/orders-dashboard/Unpaid",
  paid: "/orders-dashboard/paid",
  UnAssigned: "/orders-dashboard/unassigned",
});
const EnumOrderCreate = Object.freeze({
  FullFilable: "/create-fulfillable-order",
  Regular: "/create-regular-order",
  UploadOrders: "/upload-orders",
});
function OrderPage(props) {
  const orderListRef = useRef(null);
  const belowMdScreen = useGetBreakPoint("md");
  const theme = useTheme();
  const isSm = useGetBreakPoint("sm");
  const isXs = useGetBreakPoint("xs");
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
    loadingConfirmation,
    setLoadingConfirmation,
  } = useDateRangeHook();
  const {
    openDelete,
    setOpenDelete,
    handleDeleteClose,
    handleSetDeletedItem,
    deletedItem,
    loadingConfirmation: unassignLoading,
    setLoadingConfirmation: unassignSetLoading,
  } = useDeleteConfirmation();
  //#region location filter
  const {
    loading: countryLoading,
    countries,
    selectedCountryIds,
    setSelectedCountryIds,
  } = useGetAllCountries();
  const {
    loading: citiesLoading,
    allCities,
    handleGetAllCitiesByIds,
    selectedCities,
    setSelectedCities,
    setAllCities,
  } = useGetAllCitiesByRegionIds();
  const {
    loading: regionLoading,
    allRegions,
    handleGetAllRegionsByIds,
    selectedRegions,
    setSelectedRegions,
    setAllRegions,
  } = useGetAllRegionbyCountryIds();

  const {
    selectedAddressSchema,
    selectedAddressSchemaForMutiple,
    selectedAddressSchemaWithObjValue,
    selectedAddressSchemaWithObjValueForMultiple,
    addressSchemaSelectData,
    handleSetSchema,
    handleChangeSelectAddressSchemaAndGetOptionsForMultiple,
    handleReset,
  } = useGetAddressSchema();

  const { store, salePersonIds } = useSalePersonHook();
  useEffect(() => {
    if (selectedCountryIds.length > 0) {
      const countryIds = selectedCountryIds
        .map((country) => country.countryId)
        .join(",");

      handleGetAllRegionsByIds(countryIds);
    } else {
      setAllRegions([]);
      setAllCities([]);
    }
  }, [selectedCountryIds]);

  const [regionIncludeChecked, setRegionIncludeChecked] = useState(true);
  const getIncludeExcludeRegionIds = () => {
    let newVal = selectedRegions;
    if (!regionIncludeChecked) {
      newVal = allRegions.filter(
        (region) =>
          !selectedRegions.some((selected) => region.id === selected.id)
      );
    }
    return newVal.map((country) => country.id).join(",");
  };
  useEffect(() => {
    if (selectedRegions.length > 0) {
      let regionIds = selectedRegions.map((country) => country.id).join(",");
      handleGetAllCitiesByIds(regionIds);
      setAllCities([]);
    } else {
      setAllCities([]);
    }
  }, [selectedRegions]);

  const [cityIncludeChecked, setCityIncludeChecked] = useState(true);
  const getIncludeExcludeCityIds = () => {
    let newVal = selectedCities;
    if (!cityIncludeChecked) {
      newVal = allCities.filter(
        (region) =>
          !selectedCities.some((selected) => region.id === selected.id)
      );
    }
    return newVal.map((cty) => cty.id).join(",");
  };
  //#endregion
  const {
    openDelete: unassignOpen,
    setOpenDelete: unassignSetOpenDelete,
    handleDeleteClose: unassignHandleDeleteClose,
    handleSetDeletedItem: unassignHandleSetDeletedItem,
    deletedItem: unassignDeletedItem,
  } = useDeleteConfirmation();
  const handleConfirmationData = (selectedTrNos) => {
    let orderids = getOrderIdsByOrderNos(selectedTrNos);
    handleSetDeletedItem(orderids);
    setOpenDelete(true);
  };
  function getOrderIdsByOrderNos(orderNos) {
    const orderIds = allOrders
      ?.filter((order) => orderNos?.includes(order.OrderNo))
      .map((order) => order.OrderId);

    return orderIds.join(",");
  }
  const [showOrdersBtn, setShowOrdersBtn] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [allOrderLoading, setAllOrderLoading] = useState(true);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [inputFields, setInputFields] = useState([]);
  const [isShowFilter, setIsShowFilter] = useState(true);

  const navigate = useNavigate();

  let defaultValues = {
    startDate: null,
    endDate: null,
    storeId: [],
    orderTypeId: null,
    carrierId: null,
    fullFillmentStatusId: null,
    paymentStatusId: null,
    paymentMethodId: null,
    stationId: null,
  };
  // console.log("inputFields", inputFields);
  // const { register, getValues, setValue, reset, control } = useForm({
  //   defaultValues,
  // });

  const handleCreateOrderButtons = (val) => {
    if (val == EnumOrderCreate.Regular) {
      navigate(EnumOrderCreate.Regular);
    }
    if (val == EnumOrderCreate.FullFilable) {
      navigate(EnumOrderCreate.FullFilable);
    }
    if (val == EnumOrderCreate.UploadOrders) {
      navigate(EnumOrderCreate.UploadOrders);
    }
  };

  const [storeId, setStoreId] = useState(initialStateFilter.multiple);
  const [orderTypeId, setOrderTypeId] = useState(initialStateFilter.orderType);
  const [carrierId, setCarrierId] = useState(initialStateFilter.carrier);
  const [fullFillmentStatusId, setFullFillmentStatusId] = useState(
    initialStateFilter.common
  );
  const [paymentStatusId, setPaymentStatusId] = useState(
    initialStateFilter.common
  );
  const [paymentMethodId, setPaymentMethodId] = useState(
    initialStateFilter.paymentMethod
  );
  const [allSalesPerson, setAllSalesPerson] = useState([]);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState([]);

  const [carrierAssigned, setCarrierAssigned] = useState(carrierAssignModel[0]);
  const [stationId, setStationId] = useState(initialStateFilter.station);

  const handleFocus = (event) => event.target.select();
  const location = useLocation();
  //////filters
  ///
  const [storesForSelection, setStoresForSelection] = useState([]);
  const [allOrderTypeLookup, setAllOrderTypeLookup] = useState([]);
  const [allActiveCarriersForSelection, setAllActiveCarriersForSelection] =
    useState([]);
  const [allFullFillmentStatusLookup, setAllFullFillmentStatusLookup] =
    useState([]);
  const [allCarrierTrackingStatus, setAllCarrierTrackingStatus] = useState([]);
  const [selectedCarrierTrackingStatus, setSelectedCarrierTrackingStatus] =
    useState([]);
  const [allPaymentStatusLookup, setAllPaymentStatusLookup] = useState([]);
  const [allPaymentMethodLookup, setAllPaymentMethodLookup] = useState([]);
  const [allStationLookup, setAllStationLookup] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isTabFilter, setIsTabFilter] = useState(false);
  const [documentSetting, setDocumentSetting] = useState({});
  const resetRowRef = useRef(false);
  const getOrdersRef = useRef([]);

  let getValidateDocumentSetting = async () => {
    let res = await GetValidateDocumentSetting();
    if (res.data.result !== null) {
      setDocumentSetting(res.data.result);
    }
  };
  //#region
  let getStoresForSelection = async () => {
    let res = await GetStoresForSelection();
    // console.log("getStoresForSelection", res.data);
    if (res.data.result != null) {
      setStoresForSelection(res.data.result);
    }
  };
  const getAllOrderTypeLookup = async () => {
    try {
      const response = await GetAllOrderTypeLookup();
      //console.log("response of api of GetAllOrderTypeLookup", response);
      setAllOrderTypeLookup(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const getAllSalePersonForSelection = async () => {
    try {
      const response = await GetAllSalePersonForSelection();
      //console.log("response of api of GetAllOrderTypeLookup", response);
      setAllSalesPerson(response.data.result || []);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const getAllActiveCarriersForSelection = async () => {
    try {
      const response = await GetActiveCarriersForSelection();
      // console.log("response of api of GetAllOrderTypeLookup", response);
      setAllActiveCarriersForSelection(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const getAllFullFillmentStatusLookup = async () => {
    try {
      const response = await GetAllFullFillmentStatusLookup();
      // console.log("response of api of GetAllOrderTypeLookup", response);
      setAllFullFillmentStatusLookup(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const getAllCarrierTrackingStatusForSelection = async () => {
    try {
      const response = await GetAllCarrierTrackingStatusForSelection();
      setAllCarrierTrackingStatus(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const getAllPaymentStatusLookup = async () => {
    try {
      const response = await GetAllPaymentStatusLookup();
      // console.log("response of api of GetAllOrderTypeLookup", response);
      setAllPaymentStatusLookup(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const getAllPaymentMethodLookup = async () => {
    try {
      const response = await GetAllPaymentMethodLookup();
      //  console.log("response of api of GetAllOrderTypeLookup", response);
      setAllPaymentMethodLookup(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  const getAllStationLookup = async () => {
    try {
      const response = await GetAllStationLookup();
      // console.log("response of api of GetAllOrderTypeLookup", response);
      setAllStationLookup(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };
  //#endregion
  useEffect(() => {
    getStoresForSelection();
    getAllOrderTypeLookup();
    getAllSalePersonForSelection();
    getAllActiveCarriersForSelection();
    getAllFullFillmentStatusLookup();
    getAllCarrierTrackingStatusForSelection();
    getAllPaymentStatusLookup();
    getAllPaymentMethodLookup();
    getAllStationLookup();
    getValidateDocumentSetting();
    // getAllOrders({});
  }, []);

  const getFiltersFromStateForExport = () => {
    let search = inputFields.join();
    if (getOrdersRef.current && getOrdersRef.current?.length > 0) {
      search = getOrdersRef.current?.join();
    } else {
      search = inputFields.join();
    }
    let filters = {
      filterModel: {
        createdFrom: startDateFormated ? startDateFormated : null,
        createdTo: endDateFormated ? endDateFormated : null,
        start: 0,
        length: EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.length,
        search: search,
        sortDir: "desc",
        sortCol: 0,
      },
      storeId: storeId?.map((data) => data.storeId).toString(),
      SalePersonIds: selectedSalesPerson?.map((data) => data.id).toString(),
      orderTypeId: orderTypeId?.orderTypeId,
      carrierId: carrierId?.CarrierId,
      fullFillmentStatusId: fullFillmentStatusId?.id,
      paymentStatusId: paymentStatusId?.id,
      orderRequestVia: 0,
      paymentMethodId: paymentMethodId?.paymentMethodId,
      stationId: stationId?.productStationId,
      carrierAssign: carrierAssigned?.id,
      CountryIds: selectedCountryIds?.map((data) => data.countryId).toString(),
      RegionIds: selectedRegions?.map((data) => data.id).toString(),
      CityIds: selectedCities?.map((data) => data.id).toString(),
      carrierTrackingStatusIds: selectedCarrierTrackingStatus
        ?.map((data) => data.carrierTrackingStatusId)
        .toString(),
      IncludeRegion: regionIncludeChecked,
      IncludeCity: cityIncludeChecked,
    };
    return filters;
  };
  const [allOrdersCount, setAllOrdersCount] = useState(0);
  const getAllOrders = async ({
    _storeId = store,
    _salePersonIds = salePersonIds,
    _orderTypeId = orderTypeId?.orderTypeId,
    _carrierId = carrierId?.CarrierId ? String(carrierId?.CarrierId) : "",
    _fullFillmentStatusId = fullFillmentStatusId?.id,
    _paymentStatusId = paymentStatusId?.id,
    _paymentMethodId = paymentMethodId?.paymentMethodId,
    _stationId = String(stationId?.productStationId),
    _carrierAssigned = carrierAssigned?.id,
    searchParams = "",
    _countryIds = selectedAddressSchema.country
      ? String(selectedAddressSchema.country)
      : "",
    _cityIds = String(selectedAddressSchemaForMutiple.city),
    _provinceIds = String(selectedAddressSchemaForMutiple.province),
    _stateIds = String(selectedAddressSchemaForMutiple.state),
    _pinCodeIds = String(selectedAddressSchemaForMutiple.pinCode),
    _areaIds = String(selectedAddressSchemaForMutiple.area),
    _carrierTrackingStatusId = selectedCarrierTrackingStatus
      .map((data) => data.carrierTrackingStatusId)
      .toString(),
    _IncludeRegion = regionIncludeChecked,
    _IncludeCity = cityIncludeChecked,
  }) => {
    let data = [];
    const { response: res } = await fetchMethod(
      () =>
        GetAllOrders(
          startDateFormated,
          endDateFormated,
          _storeId,
          _salePersonIds,
          _orderTypeId,
          _carrierId,
          _fullFillmentStatusId,
          _paymentStatusId,
          _paymentMethodId,
          _stationId,
          _carrierAssigned,
          searchParams,
          _countryIds,
          _cityIds,
          _provinceIds,
          _stateIds,
          _pinCodeIds,
          _areaIds,
          _carrierTrackingStatusId,
          _IncludeRegion,
          _IncludeCity
        ),
      setAllOrderLoading,
      false
    );
    //console.log("getAllOrders", res.data.result);
    if (res?.isSuccess) {
      data = res?.result;
      setAllOrders(data?.list || []);
      setAllOrdersCount(data?.TotalCount || 0);
    }
    resetRowRef.current = false;

    return data;
  };

  const resetFilter = () => {
    //  console.log(defaultValues);
    setStartDate(defaultValues.startDate);
    setEndDate(defaultValues.endDate);
    setStoreId(initialStateFilter.multiple);
    setSelectedSalesPerson(initialStateFilter.multiple);
    setOrderTypeId(initialStateFilter.orderType);
    setCarrierId(initialStateFilter.carrier);
    setFullFillmentStatusId(initialStateFilter.fulfillment);
    setPaymentStatusId(initialStateFilter.paymentStatus);
    setPaymentMethodId(initialStateFilter.paymentMethod);
    setStationId(initialStateFilter.station);
    setCarrierAssigned(carrierAssignModel[0]);
    setSelectedCarrierTrackingStatus(initialStateFilter.multiple);
    handleReset();
  };

  const [isfilterClear, setIsfilterClear] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const handleFilterClear = async () => {
    resetFilter();
    setIsfilterClear(true);
  };
  useEffect(() => {
    if (isfilterClear) {
      getAllOrders({});
      setIsfilterClear(false);
    }
  }, [isfilterClear]);

  const handleTabChange = async (event, filterValue) => {
    resetFilter();
    setIsFilterOpen(false);
    if (filterValue === EnumOrderTabFilter.All) {
      getAllOrders({});
    } else if (filterValue === EnumOrderTabFilter.Unfulfilled) {
      setFullFillmentStatusId({ id: EnumFullFillmentStatus.UnFulfillment });
      getAllOrders({
        _fullFillmentStatusId: EnumFullFillmentStatus.UnFulfillment,
      });
    } else if (filterValue === EnumOrderTabFilter.Fullfilled) {
      setFullFillmentStatusId({ id: EnumFullFillmentStatus.Fulfillment });
      getAllOrders({
        _fullFillmentStatusId: EnumFullFillmentStatus.Fulfillment,
        _orderTypeId: 0,
      });
    } else if (filterValue === EnumOrderTabFilter.Regular) {
      setOrderTypeId({ orderTypeId: EnumOrderType.Regular });
      getAllOrders({
        _orderTypeId: EnumOrderType.Regular,
      });
    } else if (filterValue === EnumOrderTabFilter.FullFillable) {
      setOrderTypeId({ orderTypeId: EnumOrderType.FullFillable });
      getAllOrders({
        _orderTypeId: EnumOrderType.FullFillable,
      });
    } else if (filterValue === EnumOrderTabFilter.Unpaid) {
      setPaymentStatusId({ id: EnumPaymentStatus.Unpaid });
      getAllOrders({
        _paymentStatusId: EnumPaymentStatus.Unpaid,
      });
    } else if (filterValue === EnumOrderTabFilter.paid) {
      setPaymentStatusId({ id: EnumPaymentStatus.Paid });
      getAllOrders({
        _paymentStatusId: EnumPaymentStatus.Paid,
      });
    } else if (filterValue === EnumOrderTabFilter.UnAssigned) {
      //do work relaed unassigned
      setCarrierAssigned({ id: EnumCarrierAssign.UnAssigne });
      getAllOrders({
        _carrierAssigned: EnumCarrierAssign.UnAssigne,
      });
    }
    if (filterValue !== EnumOrderTabFilter.All) {
      setIsShowFilter(false);
    }
    resetFilter();
    resetRowRef.current = true;
    setIsTabFilter(true);
  };
  useEffect(() => {
    if (location.pathname === EnumOrderTabFilter.All) {
      resetFilter();
      setIsShowFilter(true);
    }
  }, [location.pathname]);

  /////actions
  const EnumOrderDashboardAction = {
    ASSIGNTOCARRIER: "AssigntoCarrier",
    ASSIGNINHOUSE: "AssignInHouse",
    FULLFILLMENT: "Fulfillment",
    UPDATESTATUS: "UpdateStatus",
    EXCELEXPORT: "ExcelExport",
    PRINTLABEL: "PrintLabel",
  };
  const [open, setOpen] = useState(false);
  const [openBillingOrder, setOpenBillingOrder] = useState(false);
  const [openFulfillment, setOpenFulfillment] = useState(false);
  const [openUpdateStatus, setOpenUpdateStatus] = useState(false);
  const [isAssignInHouse, setIsAssignInHouse] = useState(false);

  const downloadCarrierWayBillsByOrderNos = async (orderNo, awbTypeId) => {
    const params = {
      orderNos: orderNo,
      awbTypeId: awbTypeId,
    };
    await GetCarrierWayBillsByOrderNos(params)
      .then((res) => handleDownloadPdf(res, "AWB", handleResetSeelctedRows))
      .catch((e) => {
        console.log("e", e);
      })
      .finally((e) => {});
  };

  const downloadExcel = () => {
    let params = getFiltersFromStateForExport();
    ExcelExportOrders(params)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          UtilityClass.downloadExcel(res.data, "orders");
          handleResetSeelctedRows();

          // setIsLoading(false);
        } else {
          successNotification("Order not found");
          // setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log("e", e);
        errorNotification("Unable to download");
      });
  };
  const handleOnClickAction = (method) => {
    const selectedTrNos = getOrdersRef.current;
    if (selectedTrNos.length > 0) {
      method();
    } else {
      warningNotification(
        LanguageReducer?.languageType?.MUST_SELECT_SINGLE_ROW_WARNING_TOAST
      );
    }
  };
  const handleOnClickActionBtn = (method) => {
    const selectedTrNos = getOrdersRef.current;
    if (selectedTrNos.length === 0) {
      warningNotification(
        LanguageReducer?.languageType?.MUST_SELECT_SINGLE_ROW_WARNING_TOAST
      );
    } else {
      method();
    }
  };

  useEffect(() => {
    if (getOrdersRef.current && getOrdersRef.current.complete) {
      // console.log("first");
    }
  });
  const { navigateStateData } = useGetNavigateState(
    EnumNavigateState.DELIVERY_TASKS.pageName
  );
  const [showActionToolbarButtons, setShowActionToolbarButtons] =
    useState(false);

  const getOrderListData = useCallback(() => {
    return (
      <OrderList
        ref={orderListRef}
        isFilterOpen={isFilterOpen}
        loading={allOrderLoading}
        allOrders={
          inputFields.length > 0
            ? allOrders.filter((order) => inputFields.includes(order.OrderNo))
            : allOrders
        }
        allOrdersCount={allOrdersCount}
        setAllOrders={setAllOrders}
        getOrdersRef={getOrdersRef}
        resetRowRef={resetRowRef}
        setSelectedItems={setSelectedItems}
        setShowActionToolbarButtons={setShowActionToolbarButtons}
        allCarrierTrackingStatus={allCarrierTrackingStatus}
        getAllCarrierTrackingStatusForSelection={
          getAllCarrierTrackingStatusForSelection
        }
        setSelectionModel={setSelectionModel}
        getAllOrders={(paginationModel) => {
          getAllOrders({
            _storeId: store,
            _salePersonIds: salePersonIds,
            _orderTypeId: orderTypeId?.orderTypeId,
            _carrierId: carrierId?.CarrierId,
            _fullFillmentStatusId: fullFillmentStatusId?.id,
            _paymentStatusId: paymentStatusId?.id,
            _paymentMethodId: paymentMethodId?.paymentMethodId,
            _stationId: stationId?.productStationId,
            _carrierAssigned: carrierAssigned?.id,
            searchParams: "",
            _countryIds: selectedCountryIds
              ?.map((data) => data.countryId)
              .toString(),
            _regionIds: selectedRegions?.map((data) => data.id).toString(),
            _cityIds: selectedCities?.map((data) => data.id).toString(),
            _carrierTrackingStatusId: selectedCarrierTrackingStatus
              .map((data) => data.carrierTrackingStatusId)
              .toString(),
            _IncludeRegion: regionIncludeChecked,
            _IncludeCity: cityIncludeChecked,
          });
        }}
      />
    );
  }, [allOrders, isFilterOpen]);
  console.log("main page loading", allOrderLoading);
  const handleDelete = async () => {
    try {
      let param = {
        orderids: deletedItem,
      };
      const response = await DeleteOrder(param);
      if (!response.data?.isSuccess) {
        UtilityClass.showErrorNotificationWithDictionary(response.data?.errors);
      } else {
        getAllOrders({});
        handleResetSeelctedRows();
        handleDeleteClose();
        successNotification("Order Deleted successfully");
      }
    } catch (error) {
      console.error("Something went wrong", error.response);
    } finally {
    }
  };
  useSetNavigateStateData(navigateStateData, () => {
    const selectedOrder =
      navigateStateData[EnumNavigateState.DELIVERY_TASKS.state.selectedOrderNo];
    setInputFields([selectedOrder]);
  });
  const handleResetSeelctedRows = () => {
    resetRowRef.current = true;
  };
  useEffect(() => {
    if (salePersonIds && store) {
      const fetchResponse = async () => {
        const response = await getAllOrders({
          searchParams: String(inputFields),
        });
        setAllOrders(response);
      };
      fetchResponse();
    }
  }, [inputFields, store, salePersonIds]);

  const handleWayBillsByOrderNos = () => {
    if (documentSetting.DocumentTemplateId !== null) {
      const selectedTrNos = getOrdersRef.current;
      handleOnClickActionBtn(() =>
        downloadWayBillsByOrderNos(
          selectedTrNos.join(","),
          documentSetting.DocumentTemplateId
        )
      );
    } else {
      handleOnClickActionBtn(() => setOpenBillingOrder(true));
    }
  };

  const menuItems = [
    {
      label: LanguageReducer?.languageType?.ORDER_EXCEL_EXPORT,
      onClick: () => handleOnClickAction(() => downloadExcel()),
    },
    {
      label: LanguageReducer?.languageType?.ORDER_BATCH_FULLFILLMENT,
      onClick: () => handleOnClickAction(() => setOpenFulfillment(true)),
    },
    {
      label: LanguageReducer?.languageType?.ORDER_PRINT_LABELS,
      onClick: handleWayBillsByOrderNos,
    },
    {
      label: LanguageReducer?.languageType?.ORDER_PRINT_CARRIER_LABELS,
      onClick: () => {
        const selectedTrNos = getOrdersRef.current;
        handleOnClickActionBtn(() =>
          downloadCarrierWayBillsByOrderNos(
            selectedTrNos.join(","),
            EnumAwbType.Awb4x6TypeId
          )
        );
      },
    },
    {
      label: LanguageReducer?.languageType?.ORDER_REFRESH_TRACKING_STATUS,
      onClick: () => {
        orderListRef.current.Refresh();
      },
    },
  ];

  const actionBtnMenuData = !belowMdScreen
    ? [
        {
          title: LanguageReducer?.languageType?.ORDER_EXCEL_EXPORT,
          onClick: () => handleOnClickAction(() => downloadExcel()),
        },
        {
          title: LanguageReducer?.languageType?.ORDER_PRINT_LABELS,
          onClick: handleWayBillsByOrderNos,
        },
        {
          title: LanguageReducer?.languageType?.ORDER_PRINT_CARRIER_LABELS,
          onClick: () => {
            const selectedTrNos = getOrdersRef.current;
            handleOnClickActionBtn(() =>
              downloadCarrierWayBillsByOrderNos(
                selectedTrNos.join(","),
                EnumAwbType.Awb4x6TypeId
              )
            );
          },
        },
        {
          title: LanguageReducer?.languageType?.ORDER_REFRESH_TRACKING_STATUS,
          onClick: () => {
            orderListRef.current.Refresh();
          },
        },
      ]
    : null;

  return (
    <Box sx={styleSheet.pageRoot}>
      <div style={{ padding: "10px" }}>
        <Box
          sx={{
            background: "#F8F8F8",
            border: "1px solid rgba(224, 224, 224, 1)",
            borderRadius: "8px 8px 0px 0px!important",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, pr: "7px" }}
          >
            {belowMdScreen ? (
              <>
                {!showSearchBar ? (
                  <IconButton
                    onClick={() => {
                      setShowSearchBar(true);
                      setShowOrdersBtn(false);
                    }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                ) : (
                  <>
                    <Box flexGrow={1}>
                      <SearchInputAutoCompleteMultiple
                        handleFocus={handleFocus}
                        onChange={(e, value) => {
                          if (value.length <= MAX_TAGS) {
                            getAllOrders(String(value.slice(0, MAX_TAGS)));
                            setInputFields(value.slice(0, MAX_TAGS));
                          } else {
                            warningNotification(
                              LanguageReducer?.languageType
                                ?.MAXMIUM_NUMBER_REACHED
                            );
                          }
                        }}
                        inputFields={inputFields}
                        MAX_TAGS={MAX_TAGS}
                      />
                    </Box>
                    <ActionButtonCustom
                      onClick={() => {
                        setShowOrdersBtn(true);
                        setShowSearchBar(false);
                      }}
                      label={<AddCircleOutlineIcon />}
                      width={10}
                    />
                  </>
                )}
                {showOrdersBtn && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <ActionButtonCustom
                      onClick={(event) => {
                        handleCreateOrderButtons(EnumOrderCreate.Regular);
                      }}
                      label={
                        belowMdScreen
                          ? isSm
                            ? "Regular"
                            : "Regular Order"
                          : LanguageReducer?.languageType
                              ?.ORDERS_CREATE_REGULAR_ORDER
                      }
                      width={"110px"}
                      p={("4px", "8px")}
                      startIcon={<RegularOrderIcon />}
                    />
                    <ActionButtonCustom
                      onClick={(event) => {
                        handleCreateOrderButtons(EnumOrderCreate.FullFilable);
                      }}
                      label={
                        belowMdScreen
                          ? isSm
                            ? "Fulfillable"
                            : "Fulfillable Order"
                          : LanguageReducer?.languageType
                              ?.ORDERS_CREATE_FULFILLABLE_ORDER
                      }
                      width="110px"
                      p={("4px", "8px")}
                      startIcon={<FulfillableOrderIcon />}
                    />
                    <ActionButtonCustom
                      onClick={(event) => {
                        handleCreateOrderButtons(EnumOrderCreate.UploadOrders);
                      }}
                      p={("4px", "8px")}
                      label={
                        isSm
                          ? "Upload"
                          : LanguageReducer?.languageType?.ORDERS_UPLOAD_ORDER
                      }
                      width="110px"
                      startIcon={<UploadOrderIcon />}
                    />
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box flexGrow={1}>
                  <SearchInputAutoCompleteMultiple
                    handleFocus={handleFocus}
                    onChange={(e, value) => {
                      if (value.length <= MAX_TAGS) {
                        getAllOrders(String(value.slice(0, MAX_TAGS)));
                        setInputFields(value.slice(0, MAX_TAGS));
                      } else {
                        warningNotification(
                          LanguageReducer?.languageType?.MAXMIUM_NUMBER_REACHED
                        );
                      }
                    }}
                    inputFields={inputFields}
                    MAX_TAGS={MAX_TAGS}
                  />
                </Box>
                {showOrdersBtn ? (
                  <ActionButtonCustom
                    onClick={() => {
                      setShowOrdersBtn(false);
                    }}
                    label={<RemoveCircleOutlineIcon />}
                    width={10}
                  />
                ) : (
                  <ActionButtonCustom
                    onClick={() => {
                      setShowOrdersBtn(true);
                    }}
                    label={<AddCircleOutlineIcon />}
                    width={10}
                  />
                )}
                {showOrdersBtn && (
                  <>
                    <ActionButtonCustom
                      onClick={(event) => {
                        handleCreateOrderButtons(EnumOrderCreate.Regular);
                      }}
                      label={
                        LanguageReducer?.languageType
                          ?.ORDERS_CREATE_REGULAR_ORDER
                      }
                      width="120px"
                      startIcon={<RegularOrderIcon />}
                    />
                    <ActionButtonCustom
                      onClick={(event) => {
                        handleCreateOrderButtons(EnumOrderCreate.FullFilable);
                      }}
                      label={
                        LanguageReducer?.languageType
                          ?.ORDERS_CREATE_FULFILLABLE_ORDER
                      }
                      width="120px"
                      startIcon={<FulfillableOrderIcon />}
                    />
                    <ActionButtonCustom
                      onClick={(event) => {
                        handleCreateOrderButtons(EnumOrderCreate.UploadOrders);
                      }}
                      label={LanguageReducer?.languageType?.ORDERS_UPLOAD_ORDER}
                      width="120px"
                      startIcon={<UploadOrderIcon />}
                    />
                  </>
                )}
              </>
            )}
          </Box>

          <DataGridTabs
            customBorderRadius={"0px !important"}
            handleTabChange={handleTabChange}
            tabData={[
              {
                label: LanguageReducer?.languageType?.ORDERS_ALL,
                route: "/orders-dashboard",
              },
              {
                label: LanguageReducer?.languageType?.ORDERS_REGULAR,
                route: "/orders-dashboard/regular",
              },
              {
                label: LanguageReducer?.languageType?.ORDERS_FULFILLABLE,
                route: "/orders-dashboard/fullFillable",
              },
              {
                label: LanguageReducer?.languageType?.ORDERS_UNFULFILLED,
                route: "/orders-dashboard/Unfullfilled",
              },
              {
                label: LanguageReducer?.languageType?.ORDERS_UNASSIGNED,
                route: "/orders-dashboard/unassigned",
              },
              {
                label: LanguageReducer?.languageType?.ORDERS_FULFILLED,
                route: "/orders-dashboard/fullfilled",
              },

              {
                label: LanguageReducer?.languageType?.ORDERS_UNPAID,
                route: "/orders-dashboard/Unpaid",
              },
              {
                label: LanguageReducer?.languageType?.ORDERS_PAID,
                route: "/orders-dashboard/paid",
              },
            ]}
            actionBtnMenuData={actionBtnMenuData}
            handleFilterBtnOnClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
            responsiveButton={
              <>
                {belowMdScreen && menuItems.length > 0 ? (
                  <MenuIconComponent menuItems={menuItems} />
                ) : null}
              </>
            }
          />
          {isFilterOpen ? (
            <Table
              sx={{ ...styleSheet.generalFilterArea }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <Grid container spacing={1} sx={{ p: "15px" }}>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <Grid>
                        <InputLabel
                          sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                        >
                          {LanguageReducer?.languageType?.ORDER_START_DATE}
                        </InputLabel>

                        <CustomReactDatePickerInputFilter
                          value={startDate}
                          onClick={(date) => setStartDate(date)}
                          size="small"
                          isClearable
                          maxDate={UtilityClass.todayDate()}

                          // inputProps={{ style: { padding: "2px 5px" } }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <Grid>
                        <InputLabel
                          sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                        >
                          {LanguageReducer?.languageType?.ORDER_END_DATE}
                        </InputLabel>
                        <CustomReactDatePickerInputFilter
                          value={endDate}
                          onClick={(date) => setEndDate(date)}
                          size="small"
                          minDate={startDate}
                          disabled={!startDate ? true : false}
                          isClearable
                          maxDate={UtilityClass.todayDate()}

                          // inputProps={{ style: { padding: "2px 5px" } }}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid item minWidth={"16.66%"}>
                      <Grid>
                        <InputLabel
                          sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                        >
                          {LanguageReducer?.languageType?.SELECT_STORE_TEXT}
                        </InputLabel>
                        <SelectComponent
                          multiple={true}
                          height={28}
                          name="reason"
                          options={storesForSelection}
                          value={storeId}
                          optionLabel={EnumOptions.STORE.LABEL}
                          optionValue={EnumOptions.STORE.VALUE}
                          getOptionLabel={(option) => option?.storeName}
                          onChange={(e, val) => {
                            setStoreId(val);
                          }}
                        />
                      </Grid>
                    </Grid> */}

                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.ORDER_ORDER_TYPE}
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={allOrderTypeLookup}
                        value={orderTypeId}
                        optionLabel={EnumOptions.ORDER_TYPE.LABEL}
                        optionValue={EnumOptions.ORDER_TYPE.VALUE}
                        getOptionLabel={(option) => option.orderTypeName}
                        onChange={(e, val) => {
                          setOrderTypeId(val);
                        }}
                      />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.ORDER_CARRIER}
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={allActiveCarriersForSelection}
                        value={carrierId}
                        optionLabel={EnumOptions.CARRIER.LABEL}
                        optionValue={EnumOptions.CARRIER.VALUE}
                        getOptionLabel={(option) => option.Name}
                        onChange={(e, val) => {
                          setCarrierId(val);
                        }}
                      />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {
                          LanguageReducer?.languageType
                            ?.ORDER_FULFILLMENT_STATUS
                        }
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={allFullFillmentStatusLookup}
                        value={fullFillmentStatusId}
                        optionLabel={EnumOptions.FULL_FILLMENT_STATUS.LABEL}
                        optionValue={EnumOptions.FULL_FILLMENT_STATUS.VALUE}
                        getOptionLabel={(option) => option.text}
                        onChange={(e, val) => {
                          setFullFillmentStatusId(val);
                        }}
                      />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.ORDER_PAYMENT_STATUS}
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={allPaymentStatusLookup}
                        value={paymentStatusId}
                        optionLabel={EnumOptions.PAYMENT_STATUS.LABEL}
                        optionValue={EnumOptions.PAYMENT_STATUS.VALUE}
                        getOptionLabel={(option) => option.text}
                        onChange={(e, val) => {
                          setPaymentStatusId(val);
                        }}
                      />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.ORDER_PAYMENT_METHOD}
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={allPaymentMethodLookup}
                        value={paymentMethodId}
                        optionLabel={EnumOptions.PAYMENT_METHOD.LABEL}
                        optionValue={EnumOptions.PAYMENT_METHOD.VALUE}
                        getOptionLabel={(option) => option.pmName}
                        onChange={(e, val) => {
                          setPaymentMethodId(val);
                        }}
                      />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.ORDER_STATION}
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={allStationLookup}
                        value={stationId}
                        optionLabel={EnumOptions.SELECT_STATION.LABEL}
                        optionValue={EnumOptions.SELECT_STATION.VALUE}
                        getOptionLabel={(option) => option.sname}
                        onChange={(e, val) => {
                          setStationId(val);
                        }}
                      />
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {LanguageReducer?.languageType?.ORDER_ASSIGN}
                      </InputLabel>
                      <SelectComponent
                        name="reason"
                        height={28}
                        options={carrierAssignModel}
                        value={carrierAssigned}
                        optionLabel="text"
                        optionValue="id"
                        onChange={(e, val) => {
                          setCarrierAssigned(val);
                        }}
                      />
                    </Grid>
                    {/* <Grid item minWidth={"16.66%"}>
                      <InputLabel
                        sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                      >
                        {"Sales Person"}
                      </InputLabel>
                      <SelectComponent
                        multiple={true}
                        height={28}
                        name="reason"
                        options={allSalesPerson}
                        value={selectedSalesPerson}
                        optionLabel={EnumOptions.SALES_PERSON.LABEL}
                        optionValue={EnumOptions.SALES_PERSON.VALUE}
                        getOptionLabel={(option) => option.text}
                        onChange={(e, val) => {
                          setSelectedSalesPerson(val);
                        }}
                      />
                    </Grid> */}
                    <>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <InputLabel
                          sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                        >
                          {LanguageReducer?.languageType?.ORDER_COUNTRY}
                        </InputLabel>
                        <SelectComponent
                          name="country"
                          options={countries}
                          required={true}
                          height={28}
                          optionLabel={EnumOptions.COUNTRY.LABEL}
                          optionValue={EnumOptions.COUNTRY.VALUE}
                          value={selectedAddressSchemaWithObjValue.country}
                          onChange={(name, newValue) => {
                            const resolvedId = newValue ? newValue : null;
                            handleSetSchema("country", resolvedId);
                          }}
                        />
                      </Grid>

                      {[...addressSchemaSelectData].map((input, index) => (
                        <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                          <SchemaTextField
                            loading={input.loading}
                            disabled={input.disabled}
                            multiple={true}
                            height={28}
                            type={input.type}
                            name={input.key}
                            required={false}
                            optionLabel={addressSchemaEnum[input.key]?.LABEL}
                            optionValue={addressSchemaEnum[input.key]?.VALUE}
                            options={input.options}
                            label={input.label}
                            value={
                              selectedAddressSchemaWithObjValueForMultiple[
                                input.key
                              ]
                            }
                            onChange={(name, value) => {
                              handleChangeSelectAddressSchemaAndGetOptionsForMultiple(
                                input.key,
                                index,
                                value,
                                () => {},
                                input.key
                              );
                            }}
                          />
                        </Grid>
                      ))}
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <InputLabel
                          sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                        >
                          {
                            LanguageReducer?.languageType
                              ?.ORDER_CARRIER_TRACKING_STATUS
                          }
                        </InputLabel>
                        <SelectComponent
                          multiple={true}
                          height={28}
                          optionLabel={
                            EnumOptions.CARRIER_TRACKING_STATUS.LABEL
                          }
                          optionValue={
                            EnumOptions.CARRIER_TRACKING_STATUS.VALUE
                          }
                          name="carrierStatus"
                          options={allCarrierTrackingStatus}
                          value={selectedCarrierTrackingStatus}
                          getOptionLabel={(option) => option.text}
                          onChange={(e, val) => {
                            console.log(val);
                            setSelectedCarrierTrackingStatus(val);
                          }}
                        />
                      </Grid>
                    </>
                    <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                      <Stack
                        alignItems="flex-end"
                        direction="row"
                        spacing={1}
                        sx={{
                          height: "100%",
                        }}
                      >
                        <Button
                          sx={{
                            ...styleSheet.filterIcon,
                            minWidth: "80px",
                          }}
                          color="inherit"
                          variant="outlined"
                          onClick={() => {
                            handleFilterClear();
                          }}
                        >
                          {LanguageReducer?.languageType?.ORDER_CLEAR_FILTER}
                        </Button>
                        <Button
                          sx={{
                            ...styleSheet.filterIcon,
                            minWidth: "80px",
                          }}
                          variant="contained"
                          onClick={() => {
                            getAllOrders({});
                          }}
                        >
                          {LanguageReducer?.languageType?.ORDERS_FILTER}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </TableRow>
              </TableHead>
            </Table>
          ) : null}
        </Box>

        <Routes>
          <Route path="/" element={getOrderListData()} />
          <Route path="/Unfullfilled" element={getOrderListData()} />
          <Route
            loading={allOrderLoading}
            path="/fullfilled"
            element={getOrderListData()}
          />
          <Route
            loading={allOrderLoading}
            path="/regular"
            element={getOrderListData()}
          />
          <Route
            loading={allOrderLoading}
            path="/fullFillable"
            element={getOrderListData()}
          />
          <Route
            loading={allOrderLoading}
            path="/Unpaid"
            element={getOrderListData()}
          />
          <Route
            loading={allOrderLoading}
            path="/paid"
            element={getOrderListData()}
          />
          <Route
            loading={allOrderLoading}
            path="/unassigned"
            element={getOrderListData()}
          />
        </Routes>
      </div>
      {open && (
        <BatchAssigntoCarrierModal
          isAssignInHouse={isAssignInHouse}
          setIsAssignInHouse={setIsAssignInHouse}
          resetRowRef={resetRowRef}
          getAllOrders={() => getAllOrders({})}
          orderNosData={getOrdersRef.current}
          activeCarriers={allActiveCarriersForSelection}
          open={open}
          setOpen={setOpen}
          {...props}
        />
      )}
      {openFulfillment && (
        <BatchOrderFulfillmentModal
          resetRowRef={resetRowRef}
          getAllOrders={() => getAllOrders({})}
          orderNosData={getOrdersRef.current}
          allFullFillment={allFullFillmentStatusLookup}
          open={openFulfillment}
          setOpen={setOpenFulfillment}
          {...props}
        />
      )}
      {openUpdateStatus && (
        <BatchUpdateOrderStatusModal
          resetRowRef={resetRowRef}
          getAllOrders={() => getAllOrders({})}
          orderNosData={getOrdersRef.current}
          allCarrierTrackingStatus={allCarrierTrackingStatus}
          open={openUpdateStatus}
          setOpen={setOpenUpdateStatus}
          {...props}
        />
      )}
      {openDelete && (
        <DeleteConfirmationModal
          open={openDelete}
          setOpen={setOpenDelete}
          handleDelete={handleDelete}
        />
      )}
      {openBillingOrder && (
        <AddBillingModal
          open={openBillingOrder}
          orderNosData={getOrdersRef.current}
          onClose={() => setOpenBillingOrder(false)}
          documentSetting={documentSetting}
        />
      )}
    </Box>
  );
}
export default OrderPage;
