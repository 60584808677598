export const RegularOrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 44 40"
      fill="none"
    >
      <path
        d="M43.75 1.73541C43.75 1.30648 43.4199 0.950337 42.9961 0.92082C36.5901 0.690613 23.1131 0.232157 16.6366 0C16.494 0.0137728 16.3553 0.0491893 16.2225 0.102312L0.39453 9.18459C0.166015 9.33807 0.01953 9.59188 0 9.86736V38.4269C0 38.8617 0.34961 39.2139 0.78125 39.2139H34.6993C34.9121 39.21 35.1172 39.1195 35.2637 38.962C35.2871 38.966 43.5292 29.6063 43.5487 29.6002H43.5507C43.6776 29.4566 43.7479 29.2697 43.7479 29.0768C43.7518 29.0709 43.7422 1.73944 43.75 1.73541ZM34.379 9.08042H23.9315L31.7285 2.1016L41.119 2.43019L34.379 9.08042ZM16.805 1.58178L21.1487 1.73328L10.6797 9.08031H3.73425L16.805 1.58178ZM29.465 2.02449L21.58 9.08239L13.412 9.08042L23.75 1.82406L29.465 2.02449ZM11.705 10.6544H21.0935V19.3907L16.7224 17.3956C16.5173 17.3031 16.2829 17.3031 16.0778 17.3956L11.7067 19.3907L11.705 10.6544ZM33.918 37.6399H1.56249V10.6544H10.1425V20.6107C10.1464 20.8763 10.2812 21.1242 10.5038 21.2678C10.7265 21.4134 11.0058 21.4351 11.246 21.3269L16.3985 18.9756L21.551 21.3269H21.553C21.7952 21.439 22.0764 21.4174 22.2991 21.2718C22.5217 21.1281 22.6565 20.8783 22.6565 20.6107V10.6545H33.918L33.918 37.6399ZM42.1855 28.7777L35.4805 36.361V10.1981L42.1855 3.58094V28.7777Z"
        fill="white"
      />
      <path
        d="M12.6875 29.838H4.28699C3.85535 29.838 3.50574 30.1902 3.50574 30.6251V34.8554C3.50574 35.2902 3.85535 35.6424 4.28699 35.6424H12.6875C12.8945 35.6424 13.0937 35.5598 13.2402 35.4122C13.3867 35.2646 13.4688 35.0659 13.4688 34.8554V30.625C13.4688 30.1902 13.1191 29.838 12.6875 29.838ZM11.9062 34.0683H5.06824V31.4121H11.9062V34.0683Z"
        fill="white"
      />
    </svg>
  );
};
export const FulfillableOrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 60 60"
      fill="none"
    >
      <path
        d="M29.112 25.8L29.55 9.29996C29.568 8.50196 30.786 8.53196 30.768 9.32996L30.324 25.872L32.46 23.46C32.988 22.86 33.9 23.664 33.366 24.264L30.198 27.852C29.964 28.158 29.496 28.176 29.244 27.87L26.154 24.138C25.638 23.52 26.58 22.74 27.09 23.358L29.112 25.8ZM27.522 18.798C28.26 18.48 28.74 19.602 28.002 19.92L14.976 25.512L30.012 31.542C30.024 31.542 30.036 31.542 30.048 31.542L45.006 25.656L31.758 19.968C31.02 19.656 31.5 18.534 32.238 18.846L46.344 24.906L50.388 20.208L34.968 13.794L32.592 16.164C32.028 16.734 31.164 15.87 31.734 15.306L34.392 12.648C34.56 12.474 34.824 12.414 35.058 12.516C37.05 13.344 51.192 18.99 51.792 19.506C52.05 19.728 52.074 20.112 51.858 20.364L47.364 25.584L52.308 30.528C52.626 30.852 52.5 31.392 52.08 31.536L47.136 33.66L47.178 42.732C47.178 42.978 47.034 43.194 46.818 43.284L29.91 51.222C29.664 51.336 29.382 51.276 29.208 51.084L13.71 44.658C13.47 44.58 13.296 44.352 13.296 44.082V33.696L7.92003 31.386C7.50003 31.242 7.36803 30.696 7.69203 30.378L12.378 25.692L7.78803 20.364C7.56603 20.112 7.59603 19.728 7.84803 19.506C8.44203 18.996 22.602 13.362 24.582 12.54C24.828 12.438 25.098 12.504 25.266 12.69L28.152 15.564C28.716 16.128 27.858 16.986 27.294 16.422L24.678 13.818L9.25803 20.208L13.302 24.906L27.522 18.798ZM29.07 49.71L29.394 34.05L25.566 38.472C25.398 38.682 25.11 38.766 24.852 38.652L14.52 34.218V43.674L29.07 49.71ZM31.062 32.454L35.094 37.506L50.808 30.756L46.452 26.394L31.062 32.454ZM45.96 42.348L45.924 34.176L35.166 38.796C34.836 38.952 34.572 38.796 34.38 38.562L30.756 34.056L30.324 49.686L45.96 42.348ZM13.548 26.25L9.19203 30.606L14.148 32.736L24.918 37.362L29.13 32.496L13.548 26.25Z"
        fill="white"
      />
    </svg>
  );
};
export const UploadOrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="32"
      viewBox="0 0 60 74"
      fill="none"
    >
      <path
        d="M40.8109 41.4311C40.4785 41.1093 39.9523 41.1093 39.6198 41.4311L35.8485 45.1065C35.5225 45.4367 35.5225 45.9673 35.8464 46.2997C36.1703 46.6321 36.7008 46.6448 37.0396 46.3274L39.3641 44.0625V52.253C39.3641 52.7239 39.7455 53.1053 40.2164 53.1053C40.6852 53.1053 41.0687 52.7239 41.0687 52.253V44.0625L43.3932 46.3274H43.3911C43.7299 46.6427 44.2605 46.6321 44.5843 46.2997C44.9082 45.9673 44.9082 45.4368 44.5822 45.1065L40.8109 41.4311Z"
        fill="white"
      />
      <path
        d="M49.6599 47.148C49.6577 44.2226 48.2984 41.4655 45.9802 39.684V12.4707C45.9781 10.4807 44.363 8.86559 42.3708 8.86346H22.6853C21.7308 8.87625 20.8167 9.25551 20.1349 9.92241L11.3989 18.6666C10.732 19.3484 10.3527 20.2625 10.34 21.217V52.9832C10.3442 54.9732 11.9571 56.5883 13.9493 56.5905H40.2119C42.7133 56.5819 45.1104 55.5848 46.8812 53.8142C48.6518 52.0457 49.6489 49.6487 49.6596 47.1471L49.6599 47.148ZM47.9553 47.148C47.9489 51.42 44.4887 54.8825 40.2164 54.8869C29.957 54.4799 29.9591 39.8144 40.2164 39.4096C44.4884 39.4139 47.9488 42.8757 47.9553 47.148ZM20.6935 11.7742V18.2405C20.6935 18.7839 20.2525 19.2249 19.7091 19.2249H13.2488L20.6935 11.7742ZM13.9501 54.8864C12.8996 54.8842 12.0473 54.0341 12.0452 52.9836V21.2175C12.0474 21.1216 12.058 21.0257 12.075 20.9298H19.7092C21.1943 20.9298 22.396 19.726 22.3982 18.2409V10.598C22.494 10.5809 22.5899 10.5703 22.6858 10.5682H42.3713C43.4217 10.5703 44.274 11.4204 44.2761 12.4709V38.6336C43.0083 38.0221 41.6213 37.7046 40.2171 37.7046C30.9744 37.7345 27.333 49.6921 34.8201 54.8865L13.9501 54.8864Z"
        fill="white"
      />
      <path
        d="M17.297 28.0653H30.7844C31.2489 28.0546 31.6218 27.6775 31.6218 27.213C31.6218 26.7485 31.2489 26.3692 30.7844 26.3607H17.297C16.8325 26.3692 16.4596 26.7485 16.4596 27.213C16.4596 27.6775 16.8325 28.0546 17.297 28.0653Z"
        fill="white"
      />
      <path
        d="M25.2742 31.8752H17.297C16.8325 31.8838 16.4596 32.263 16.4596 32.7275C16.4596 33.192 16.8325 33.5713 17.297 33.5798H25.2742C25.7387 33.5713 26.1094 33.192 26.1094 32.7275C26.1094 32.263 25.7387 31.8838 25.2742 31.8752Z"
        fill="white"
      />
    </svg>
  );
};
export const MinusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M25 0C20.0555 0 15.222 1.46622 11.1108 4.21326C6.99953 6.96029 3.79521 10.8648 1.90302 15.4329C0.0108321 20.0011 -0.484251 25.0277 0.480379 29.8772C1.44501 34.7268 3.82603 39.1813 7.32234 42.6777C10.8187 46.174 15.2732 48.555 20.1227 49.5196C24.9723 50.4842 29.9989 49.9892 34.5671 48.097C39.1352 46.2048 43.0397 43.0005 45.7867 38.8892C48.5338 34.778 50 29.9445 50 25C50 18.3696 47.3661 12.0107 42.6777 7.32233C37.9893 2.63392 31.6304 0 25 0ZM25 47.2222C20.6049 47.2222 16.3084 45.9189 12.654 43.4771C8.99958 41.0353 6.1513 37.5646 4.46935 33.5041C2.78741 29.4435 2.34733 24.9753 3.20478 20.6647C4.06223 16.354 6.17869 12.3943 9.28653 9.28651C12.3944 6.17868 16.354 4.06222 20.6647 3.20477C24.9754 2.34732 29.4435 2.7874 33.5041 4.46934C37.5647 6.15129 41.0353 8.99957 43.4771 12.654C45.9189 16.3084 47.2222 20.6049 47.2222 25C47.2222 30.8937 44.881 36.546 40.7135 40.7135C36.546 44.8809 30.8937 47.2222 25 47.2222Z"
        fill="white"
      />
    </svg>
  );
};
export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M25 0C20.0555 0 15.222 1.46622 11.1108 4.21326C6.99953 6.96029 3.79521 10.8648 1.90302 15.4329C0.0108321 20.0011 -0.484251 25.0277 0.480379 29.8772C1.44501 34.7268 3.82603 39.1813 7.32234 42.6777C10.8187 46.174 15.2732 48.555 20.1227 49.5196C24.9723 50.4842 29.9989 49.9892 34.5671 48.097C39.1352 46.2048 43.0397 43.0005 45.7867 38.8892C48.5338 34.778 50 29.9445 50 25C50 18.3696 47.3661 12.0107 42.6777 7.32233C37.9893 2.63392 31.6304 0 25 0ZM25 47.2222C20.6049 47.2222 16.3084 45.9189 12.654 43.4771C8.99958 41.0353 6.1513 37.5646 4.46935 33.5041C2.78741 29.4435 2.34733 24.9753 3.20478 20.6647C4.06223 16.354 6.17869 12.3943 9.28653 9.28651C12.3944 6.17868 16.354 4.06222 20.6647 3.20477C24.9754 2.34732 29.4435 2.7874 33.5041 4.46934C37.5647 6.15129 41.0353 8.99957 43.4771 12.654C45.9189 16.3084 47.2222 20.6049 47.2222 25C47.2222 30.8937 44.881 36.546 40.7135 40.7135C36.546 44.8809 30.8937 47.2222 25 47.2222Z"
        fill="white"
      />
    </svg>
  );
};
