import { Box, InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { inputTypesEnum } from "../../.reUseableComponents/Modal/ConfigSettingModal";
import SelectComponent from "../../.reUseableComponents/TextField/SelectComponent";
import {
  GetAddressEntitiesByType,
  GetAllCountry,
} from "../../api/AxiosInterceptors";
import { styleSheet } from "../../assets/styles/style";
import { getThisKeyCookie } from "../cookies";
import { EnumCookieKeys, EnumOptions } from "../enum";
import {
  ActionButtonCustom,
  LoadingTextField,
  fetchMethod,
  getLowerCase,
  useLanguageReducer,
} from "./Helpers";

export const addressSchemaEnum = Object.freeze({
  area: {
    LABEL: "name",
    VALUE: "areaId",
  },
  state: {
    LABEL: "name",
    VALUE: "stateId",
  },
  province: {
    LABEL: "name",
    VALUE: "provinceId",
  },
  city: {
    LABEL: "name",
    VALUE: "cityId",
  },
  pinCode: {
    LABEL: "pinCodeValue",
    VALUE: "pinCodeId",
  },
});

export const handleGetAllCountries = async () => {
  let data = [];
  const { response } = await fetchMethod(() => GetAllCountry({}));
  if (response.isSuccess) {
    data = response.result;
  }
  return data;
};

export const handleGetAddressEntitiesByType = async (
  SelectedEntityIds,
  SelectedEntityType,
  NextEntityType
) => {
  let data = [];
  const { response } = await fetchMethod(() =>
    GetAddressEntitiesByType(
      SelectedEntityIds,
      SelectedEntityType,
      NextEntityType
    )
  );
  if (response.isSuccess) {
    data = response.result;
  }
  return data;
};
export const handleGetOptionObjByOptionId = (options = [], key, id) => {
  const _selectedOption = options.find((dt) => dt[key] === id);
  return _selectedOption;
};
export const handleGetOptionIndexByOptionId = (options = [], key, value) => {
  const _selectedOptionIndex = options.findIndex((dt) => dt[key] === value);
  return _selectedOptionIndex;
};

export const useGetAddressSchema = (
  setValue = () => {},
  showDefaultCountry
) => {
  // obj values
  const initialSelectedAddressSchemaWithObjValue = {
    country: null,
    city: null,
    state: null,
    province: null,
    pinCode: null,
    area: null,
  };
  const [
    selectedAddressSchemaWithObjValue,
    setSelectedAddressSchemaWithObjValue,
  ] = useState(initialSelectedAddressSchemaWithObjValue);

  const initialSelectedAddressSchemaWithObjValueForMultiple = {
    city: [],
    state: [],
    province: [],
    pinCode: [],
    area: [],
  };
  const [
    selectedAddressSchemaWithObjValueForMultiple,
    setSelectedAddressSchemaWithObjValueForMultiple,
  ] = useState(initialSelectedAddressSchemaWithObjValueForMultiple);
  // ids

  const initialSelectedAddressSchemaMoreInfoFields = {
    houseNo: "",
    buildingName: "",
    landmark: "",
    zip: "",
  };
  const initialSelectedAddressSchema = {
    country: null,
    city: null,
    state: null,
    province: null,
    pinCode: null,
    area: null,
    streetAddress: "",
    streetAddress2: "",
    ...initialSelectedAddressSchemaMoreInfoFields,
  };

  const [selectedAddressSchema, setSelectedAddressSchema] = useState(
    initialSelectedAddressSchema
  );
  const initialSelectedAddressSchemaForMutiple = {
    city: [],
    state: [],
    province: [],
    pinCode: [],
    area: [],
  };

  const [selectedAddressSchemaForMutiple, setSelectedAddressSchemaForMutiple] =
    useState(initialSelectedAddressSchemaForMutiple);
  const [addressSchema, setAddressSchema] = useState({});
  const [addressSchemaSelectData, setAddressSchemaSelectData] = useState([]);
  const [addressSchemaInputData, setAddressSchemaInputData] = useState([]);
  const moreInfoInputData = [
    {
      key: "houseNo",
      required: false,
      label: "House No",
      type: "text",
    },
    {
      key: "buildingName",
      required: false,
      label: "Building Name",
      type: "text",
    },
    {
      key: "landmark",
      required: false,
      label: "Landmark",
      type: "text",
    },
    {
      key: "zip",
      required: false,
      label: "Zip",
      type: "text",
    },
  ];
  const [showMoreInfoBtn, setShowMoreInfoBtn] = useState(false);

  const handleAddRemoveMoreInfoFields = (value, setValue = () => {}) => {
    setShowMoreInfoBtn(value);
    if (value) {
      setAddressSchemaInputData((prev) => [...prev, ...moreInfoInputData]);
      return;
    }
    Object.keys(initialSelectedAddressSchemaMoreInfoFields).forEach((key) => {
      setValue(key, "");
      setSelectedAddressSchema((prev) => ({
        ...prev,
        [key]: "",
      }));
    });
    setAddressSchemaInputData((prev) =>
      prev.filter(
        (item) => !moreInfoInputData.some((item2) => item2.key === item.key)
      )
    );
  };

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    getThisKeyCookie(EnumCookieKeys.COUNTRY_CODE)
  );
  const handleCountryChange = (newCountryCode) => {
    setSelectedCountryCode(newCountryCode);
    return { prev: selectedCountryCode, next: newCountryCode };
  };

  // for country change
  const handleSetSchema = async (
    key,
    selectedCountry = {},
    setValue = () => {},
    unregister = () => {}
  ) => {
    setShowMoreInfoBtn(false);
    const schema = selectedCountry?.addressingScheme;
    const _selectedAddressSchema = {};
    Object.keys(selectedAddressSchema).forEach((schema_key) => {
      _selectedAddressSchema[schema_key] = "";
      setValue(schema_key, "");
      if (schema_key !== "country") {
        unregister(schema_key);
      }
    });
    setValue(key, null);
    setSelectedAddressSchemaWithObjValue(
      initialSelectedAddressSchemaWithObjValue
    );
    setSelectedAddressSchemaWithObjValueForMultiple(
      initialSelectedAddressSchemaWithObjValueForMultiple
    );
    setSelectedAddressSchemaForMutiple(initialSelectedAddressSchemaForMutiple);
    setSelectedAddressSchema(_selectedAddressSchema);
    setAddressSchema({});
    setAddressSchemaSelectData([]);
    setAddressSchemaInputData([]);
    const { prev, next } = handleCountryChange(
      selectedCountry
        ? selectedCountry?.mapCountryCode
        : getThisKeyCookie(EnumCookieKeys.COUNTRY_CODE)
    );
    if (prev !== next) {
      unregister("mobile1");
      unregister("mobile2");
      unregister("phone");
      unregister("phoneNo");
      unregister("mobile");
      unregister("mobileNo");
      unregister("customerServiceNo");
    }
    if (schema) {
      const value = selectedCountry[EnumOptions.COUNTRY.VALUE];
      setValue(key, selectedCountry);
      setSelectedAddressSchemaWithObjValue((prev) => ({
        ...prev,
        [key]: selectedCountry,
      }));
      setSelectedAddressSchema((prev) => ({
        ...prev,
        country: value,
      }));
      const parsedData = JSON.parse(schema);
      const _inputData = Object.entries(parsedData)
        .map(([key, data]) => {
          if (
            key !== "keys" &&
            key !== "country" &&
            data?.type !== inputTypesEnum.SELECT
          ) {
            return { ...data, key };
          }
        })
        .filter((dt) => dt);
      setAddressSchemaInputData(_inputData);
      setAddressSchema(parsedData);
      const _selectDataInitialized = parsedData?.keys?.map((dt, index) => {
        return {
          key: dt,
          options: [],
          disabled: true,
          ...parsedData[dt],
        };
      });
      // fetch options of optional dropdowns untill get mendatory dropdown
      for (let parsedKey of parsedData?.keys) {
        let _options = [];
        //  set loading and enable input
        setAddressSchemaSelectData((prev) => {
          const _selectedAddressSchema = [..._selectDataInitialized];
          const parsedKeyIndex = _selectedAddressSchema.findIndex(
            (dt) => dt.key === parsedKey
          );
          _selectedAddressSchema[parsedKeyIndex].loading = true;
          _selectedAddressSchema[parsedKeyIndex].disabled = false;
          return _selectedAddressSchema;
        });
        _options = await handleGetAddressEntitiesByType(
          value,
          parsedData[parsedKey].parentKey,
          parsedKey
        );
        setAddressSchemaSelectData((prev) => {
          const _selectedAddressSchema = [..._selectDataInitialized];
          const parsedKeyIndex = _selectedAddressSchema.findIndex(
            (dt) => dt.key === parsedKey
          );
          _selectedAddressSchema[parsedKeyIndex].loading = false;
          _selectedAddressSchema[parsedKeyIndex].options = _options;
          return _selectedAddressSchema;
        });
        if (parsedData[parsedKey].required) {
          break;
        }
      }
    }
  };

  //reset
  const handleReset = () => {
    setSelectedAddressSchema(initialSelectedAddressSchema);
    setSelectedAddressSchemaForMutiple(initialSelectedAddressSchemaForMutiple);
    setSelectedAddressSchemaWithObjValueForMultiple(
      initialSelectedAddressSchemaWithObjValueForMultiple
    );
    setSelectedAddressSchemaWithObjValue(
      initialSelectedAddressSchemaWithObjValue
    );
  };

  // for schema select field changes
  const handleChangeSelectAddressSchemaAndGetOptions = async (
    key,
    index,
    value,
    setValue,
    name
  ) => {
    const id = value ? value[addressSchemaEnum[key]?.VALUE] : null;
    // remove next values
    const slicedKeysArrayStartIndex = addressSchema.keys?.indexOf(key) + 1;
    const slicedKeysArray = addressSchema.keys?.slice(
      slicedKeysArrayStartIndex
    );
    if (addressSchema[key].required) {
      slicedKeysArray.forEach((schema_key) => {
        setValue(schema_key, null);
        setSelectedAddressSchema((prev) => ({ ...prev, [schema_key]: null }));
        setSelectedAddressSchemaWithObjValue((prev) => ({
          ...prev,
          [schema_key]: null,
        }));
        setAddressSchemaSelectData((prev) => {
          const _schemaData = [...prev];
          const _selectedIndexBykey = handleGetOptionIndexByOptionId(
            _schemaData,
            "key",
            schema_key
          );
          _schemaData[_selectedIndexBykey].disabled = true;
          _schemaData[_selectedIndexBykey].options = [];
          return _schemaData;
        });
      });
    }
    if (id) {
      setSelectedAddressSchemaWithObjValue((prev) => ({
        ...prev,
        [key]: value,
      }));
      setSelectedAddressSchema((prev) => ({ ...prev, [key]: id }));
      setValue(name, value);
      if (addressSchema[key].required) {
        // fetch options of optional dropdowns untill get mendatory dropdown
        for (let parsedKey of slicedKeysArray) {
          let _options = [];
          //  set loading and enable input
          setAddressSchemaSelectData((prev) => {
            const _selectedAddressSchema = [...prev];
            const parsedKeyIndex = _selectedAddressSchema.findIndex(
              (dt) => dt.key === parsedKey
            );
            _selectedAddressSchema[parsedKeyIndex].loading = true;
            _selectedAddressSchema[parsedKeyIndex].disabled = false;
            return _selectedAddressSchema;
          });
          _options = await handleGetAddressEntitiesByType(
            id,
            addressSchema[parsedKey].parentKey,
            parsedKey
          );
          setAddressSchemaSelectData((prev) => {
            const _selectedAddressSchema = [...prev];
            const parsedKeyIndex = _selectedAddressSchema.findIndex(
              (dt) => dt.key === parsedKey
            );
            _selectedAddressSchema[parsedKeyIndex].loading = false;
            _selectedAddressSchema[parsedKeyIndex].options = _options;
            return _selectedAddressSchema;
          });
          if (addressSchema[parsedKey].required) {
            break;
          }
        }
      }
    } else {
      setValue(key, null);
      setSelectedAddressSchema((prev) => ({ ...prev, [key]: null }));
      setSelectedAddressSchemaWithObjValue((prev) => ({
        ...prev,
        [key]: null,
      }));
    }
  };
  // for schema select field change for multiple
  const handleChangeSelectAddressSchemaAndGetOptionsForMultiple = async (
    key,
    index,
    value,
    setValue,
    name
  ) => {
    const ids =
      value.length > 0
        ? value.map((dt) => {
            return dt[addressSchemaEnum[key]?.VALUE];
          })
        : null;
    const slicedKeysArrayStartIndex = addressSchema.keys?.indexOf(key) + 1;
    const slicedKeysArray = addressSchema.keys?.slice(
      slicedKeysArrayStartIndex
    );
    // for  remove next values
    if (addressSchema[key].required) {
      slicedKeysArray.forEach((schema_key) => {
        setValue(schema_key, []);
        setSelectedAddressSchemaForMutiple((prev) => ({
          ...prev,
          [schema_key]: [],
        }));
        setSelectedAddressSchemaWithObjValueForMultiple((prev) => ({
          ...prev,
          [schema_key]: [],
        }));
        setAddressSchemaSelectData((prev) => {
          const _schemaData = [...prev];
          const _selectedIndexBykey = handleGetOptionIndexByOptionId(
            _schemaData,
            "key",
            schema_key
          );
          _schemaData[_selectedIndexBykey].disabled = true;
          _schemaData[_selectedIndexBykey].options = [];
          return _schemaData;
        });
      });
    }
    if (ids) {
      setSelectedAddressSchemaWithObjValueForMultiple((prev) => ({
        ...prev,
        [key]: value,
      }));
      setSelectedAddressSchemaForMutiple((prev) => ({ ...prev, [key]: ids }));
      setValue(name, value);
      if (addressSchema[key].required) {
        // fetch options of optional dropdowns untill get mendatory dropdown
        for (let parsedKey of slicedKeysArray) {
          let _options = [];
          //  set loading and enable input
          setAddressSchemaSelectData((prev) => {
            const _selectedAddressSchema = [...prev];
            const parsedKeyIndex = _selectedAddressSchema.findIndex(
              (dt) => dt.key === parsedKey
            );
            _selectedAddressSchema[parsedKeyIndex].loading = true;
            _selectedAddressSchema[parsedKeyIndex].disabled = false;
            return _selectedAddressSchema;
          });
          _options = await handleGetAddressEntitiesByType(
            ids,
            addressSchema[parsedKey].parentKey,
            parsedKey
          );
          setAddressSchemaSelectData((prev) => {
            const _selectedAddressSchema = [...prev];
            const parsedKeyIndex = _selectedAddressSchema.findIndex(
              (dt) => dt.key === parsedKey
            );
            _selectedAddressSchema[parsedKeyIndex].loading = false;
            _selectedAddressSchema[parsedKeyIndex].options = _options;
            return _selectedAddressSchema;
          });
          if (addressSchema[parsedKey].required) {
            break;
          }
        }
      }
    } else {
      setValue(key, []);
      setSelectedAddressSchemaForMutiple((prev) => ({
        ...prev,
        [key]: [],
      }));
      setSelectedAddressSchemaWithObjValueForMultiple((prev) => ({
        ...prev,
        [key]: [],
      }));
    }
  };

  // for schema input field changes
  const handleChangeInputAddressSchema = async (key, value, setValue) => {
    setSelectedAddressSchema((prev) => ({
      ...prev,
      [key]: value,
    }));
    setSelectedAddressSchemaWithObjValue((prev) => ({
      ...prev,
      [key]: value,
    }));
    setValue(key, value);
  };
  // edit mode
  const handleSetSchemaValueForUpdate = async (
    data = {},
    setValue = () => {}
  ) => {
    const countries = await handleGetAllCountries();
    const selectedCountryId = data["country"];
    const selectedCountryObj = handleGetOptionObjByOptionId(
      countries,
      EnumOptions.COUNTRY.VALUE,
      selectedCountryId
    );
    setValue("country", selectedCountryObj);
    setSelectedCountryCode(selectedCountryObj?.mapCountryCode);
    setSelectedAddressSchemaWithObjValue((prev) => ({
      ...prev,
      country: selectedCountryObj,
    }));
    setSelectedAddressSchema((prev) => ({ ...prev, country: data["country"] }));
    const schema = selectedCountryObj?.addressingScheme;
    if (schema) {
      const parsedSchema = JSON.parse(schema);
      setAddressSchema(parsedSchema);
      const schemaKeys = parsedSchema.keys || [];
      let _selectDataInitialized = schemaKeys.map((dt, index) => {
        return {
          key: dt,
          options: [],
          loading: index === 0,
          disabled: index !== 0,
          ...parsedSchema[dt],
        };
      });
      const _inputData = Object.entries(parsedSchema)
        .map(([key, input_data]) => {
          if (
            key !== "keys" &&
            key !== "country" &&
            input_data?.type !== inputTypesEnum.SELECT
          ) {
            setValue(key, data[key]);
            setSelectedAddressSchemaWithObjValue((prev) => ({
              ...prev,
              [key]: data[key],
            }));
            setSelectedAddressSchema((prev) => ({ ...prev, [key]: data[key] }));
            return { ...input_data, key };
          }
        })
        .filter(Boolean);
      let showMoreInfoFields = false;

      Object.keys(initialSelectedAddressSchemaMoreInfoFields).forEach(
        (moreInfoKey) => {
          if (data[moreInfoKey]) {
            showMoreInfoFields = true;
            setValue(moreInfoKey, data[moreInfoKey]);
            setShowMoreInfoBtn(true);
            setSelectedAddressSchema((prev) => ({
              ...prev,
              [moreInfoKey]: data[moreInfoKey],
            }));
          }
        }
      );
      if (showMoreInfoFields) {
        setAddressSchemaInputData([..._inputData, ...moreInfoInputData]);
      } else {
        setAddressSchemaInputData(_inputData);
      }

      // fetch options of optional dropdowns untill get mendatory dropdown
      for (let parsedKey of schemaKeys) {
        let _options = [];
        //  set loading and enable input
        setAddressSchemaSelectData((prev) => {
          const _selectedAddressSchema = [..._selectDataInitialized];
          const parsedKeyIndex = _selectedAddressSchema.findIndex(
            (dt) => dt.key === parsedKey
          );
          _selectedAddressSchema[parsedKeyIndex].loading = true;
          _selectedAddressSchema[parsedKeyIndex].disabled = false;
          return _selectedAddressSchema;
        });
        _options = await handleGetAddressEntitiesByType(
          data[parsedSchema[parsedKey].parentKey],
          parsedSchema[parsedKey].parentKey,
          parsedKey
        );
        setValue(
          parsedKey,
          handleGetOptionObjByOptionId(
            _options,
            addressSchemaEnum[parsedKey]?.VALUE,
            data[parsedKey]
          )
        );
        setSelectedAddressSchema((prev) => ({
          ...prev,
          [parsedKey]: data[parsedKey],
        }));
        setSelectedAddressSchemaWithObjValue((prev) => ({
          ...prev,
          [parsedKey]: handleGetOptionObjByOptionId(
            _options,
            addressSchemaEnum[parsedKey]?.VALUE,
            data[parsedKey]
          ),
        }));
        setAddressSchemaSelectData((prev) => {
          const _selectedAddressSchema = [..._selectDataInitialized];
          const parsedKeyIndex = _selectedAddressSchema.findIndex(
            (dt) => dt.key === parsedKey
          );
          _selectedAddressSchema[parsedKeyIndex].loading = false;
          _selectedAddressSchema[parsedKeyIndex].options = _options;
          return _selectedAddressSchema;
        });
      }
    }
  };

  useEffect(() => {
    if (showDefaultCountry) {
      // default mode
      const handleSetDefaultSchemaValue = async () => {
        const countries = await handleGetAllCountries();

        const cookie_country_id = Number(
          getThisKeyCookie(EnumCookieKeys.COUNTRY_ID)
        );
        const selectedCountryObj = handleGetOptionObjByOptionId(
          countries,
          EnumOptions.COUNTRY.VALUE,
          cookie_country_id
        );
        const schema = selectedCountryObj?.addressingScheme;
        if (schema) {
          setValue("country", selectedCountryObj);
          setSelectedCountryCode(selectedCountryObj?.mapCountryCode);
          setSelectedAddressSchemaWithObjValue((prev) => ({
            ...prev,
            country: selectedCountryObj,
          }));
          setSelectedAddressSchema((prev) => ({
            ...prev,
            country: cookie_country_id,
          }));
          const parsedData = JSON.parse(schema);
          const _inputData = Object.entries(parsedData)
            .map(([key, data]) => {
              if (
                key !== "keys" &&
                key !== "country" &&
                data?.type !== inputTypesEnum.SELECT
              ) {
                return { ...data, key };
              }
            })
            .filter((dt) => dt);
          setAddressSchemaInputData(_inputData);
          setAddressSchema(parsedData);
          const _selectDataInitialized = parsedData?.keys?.map((dt, index) => {
            return {
              key: dt,
              options: [],
              disabled: true,
              ...parsedData[dt],
            };
          });
          // fetch options of optional dropdowns untill get mendatory dropdown
          for (let parsedKey of parsedData?.keys) {
            let _options = [];
            //  set loading and enable input
            setAddressSchemaSelectData((prev) => {
              const _selectedAddressSchema = [..._selectDataInitialized];
              const parsedKeyIndex = _selectedAddressSchema.findIndex(
                (dt) => dt.key === parsedKey
              );
              _selectedAddressSchema[parsedKeyIndex].loading = true;
              _selectedAddressSchema[parsedKeyIndex].disabled = false;
              return _selectedAddressSchema;
            });
            _options = await handleGetAddressEntitiesByType(
              cookie_country_id,
              parsedData[parsedKey].parentKey,
              parsedKey
            );
            setAddressSchemaSelectData((prev) => {
              const _selectedAddressSchema = [..._selectDataInitialized];
              const parsedKeyIndex = _selectedAddressSchema.findIndex(
                (dt) => dt.key === parsedKey
              );
              _selectedAddressSchema[parsedKeyIndex].loading = false;
              _selectedAddressSchema[parsedKeyIndex].options = _options;
              return _selectedAddressSchema;
            });
            if (parsedData[parsedKey].required) {
              break;
            }
          }
        }
      };
      handleSetDefaultSchemaValue();
    }
  }, []);

  return {
    selectedAddressSchema,
    selectedAddressSchemaForMutiple,
    selectedAddressSchemaWithObjValue,
    selectedAddressSchemaWithObjValueForMultiple,
    addressSchema,
    addressSchemaSelectData,
    addressSchemaInputData,
    showMoreInfoBtn,
    handleAddRemoveMoreInfoFields,
    handleSetSchema,
    handleChangeInputAddressSchema,
    handleChangeSelectAddressSchemaAndGetOptions,
    handleChangeSelectAddressSchemaAndGetOptionsForMultiple,
    handleSetSchemaValueForUpdate,
    handleReset,
  };
};

export const SchemaTextField = (props) => {
  const {
    bgcolor,
    isRHF = true,
    disabled,
    loading,
    type = inputTypesEnum.SELECT,
    required = true,
    options,
    optionLabel,
    optionValue,
    name,
    value,
    onChange = () => {},
    errors = [],
    label,
    register = () => {},
    multiple,
    height = "38px",
    showMoreInfoBtn,
    btnFlag,
    viewMode,
    handleClickShowMoreInfoBtn = () => {},
  } = props;
  const handleFocus = (event) => event.target.select();
  const LanguageReducer = useLanguageReducer();
  return (
    <>
      <InputLabel required={required} sx={styleSheet.inputLabel}>
        {label}
      </InputLabel>
      {loading ? (
        <LoadingTextField height={height} />
      ) : getLowerCase(type) === inputTypesEnum.SELECT ? (
        <SelectComponent
          {...register(name, {
            required: {
              value: required,
              message: LanguageReducer?.FIELD_REQUIRED_TEXT,
            },
          })}
          isRHF={isRHF}
          errors={errors}
          height={height}
          required={required}
          name={name}
          options={options}
          optionLabel={optionLabel}
          optionValue={optionValue}
          value={value || null}
          onChange={onChange}
          disabled={disabled}
          multiple={multiple}
        />
      ) : (
        <Box className={"flex_between"} gap={1}>
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                bgcolor: bgcolor,
              },
              "& .MuiInputBase-root input": {
                fontSize: "12px",
              },
            }}
            required={required}
            disabled={disabled}
            placeholder={name}
            onFocus={handleFocus}
            type={type}
            size="small"
            name={name}
            fullWidth
            {...register(name, {
              required: {
                value: required,
                message: LanguageReducer?.FIELD_REQUIRED_TEXT,
              },
            })}
            value={value}
            onChange={onChange}
            variant="outlined"
            error={isRHF ? Boolean(errors[name]) : errors.includes(name)}
            helperText={
              isRHF
                ? errors[name]?.message
                : errors.includes(name) && LanguageReducer?.FIELD_REQUIRED_TEXT
            }
          />
          {!viewMode && showMoreInfoBtn && (
            <ActionButtonCustom
              label={btnFlag ? "- More Info" : "+ More Info"}
              p={0}
              onClick={() => {
                handleClickShowMoreInfoBtn(!btnFlag);
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};
