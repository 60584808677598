import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, ButtonBase, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import {
  GreyBox,
  GridContainer,
  GridItem,
  downloadWayBillsByOrderNos,
  purple,
} from "../../../utilities/helpers/Helpers";
import { errorNotification } from "../../../utilities/toast";
import { handleDispatchUserProfile } from "../../topNavBar";

const AddBillingModal = (props) => {
  let { open, onClose, isLoading, documentSetting, orderNosData } = props;
  const [selectedTemplateSize, setSelectedTemplateSize] = useState(null);
  const [documentSizeId, setDocumentSizeId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCarrierBill = () => {
    if (!selectedTemplateSize) {
      errorNotification("Please Select templates");
      return;
    }
    const selectedTrNos = orderNosData;
    downloadWayBillsByOrderNos(selectedTrNos.join(","), documentSizeId)
      .then(() => {
        onClose();
      })
      .catch((error) => {
        errorNotification("Failed to print the label");
        console.error(error);
      });
  };

  const handleClick = (event) => {
    handleDispatchUserProfile(dispatch, true, navigate);
    navigate("/document-settings");
  };
  return (
    <>
      <ModalComponent
        open={open}
        onClose={onClose}
        maxWidth="lg"
        title={"Print Label"}
        actionBtn={
          <ModalButtonComponent
            title={"Print"}
            loading={isLoading}
            bg={purple}
            onClick={handleCarrierBill}
          />
        }
        component={"form"}
      >
        <GridContainer>
          {documentSetting?.result?.map((res, index) => (
            <GridItem xs={12} md={8} lg={8} key={index}>
              <Box display="flex" gap={2}>
                {res.documentTemplate?.map((data) => (
                  <Box
                    key={data.documentTemplateId}
                    width="100%"
                    position="relative"
                    mb={2}
                  >
                    <GreyBox
                      key={data.documentTemplateId}
                      bg={
                        selectedTemplateSize === data.documentTemplateId
                          ? "lightgrey"
                          : undefined
                      }
                      className="flex_center"
                      component={ButtonBase}
                      width="100%"
                      onClick={() => {
                        setSelectedTemplateSize(data.documentTemplateId);
                        setDocumentSizeId(data.documentTemplateId);
                      }}
                    >
                      <img
                        width="250px"
                        height="250px"
                        src={data.sampleImageUrl}
                        alt="Image Not Found"
                      />
                    </GreyBox>
                    <Typography className="flex_center" variant="h5" mt={1}>
                      {data.name}
                    </Typography>
                    <Button
                      onClick={() => window.open(data.samplePdfUrl, "_blank")}
                      className="eyeBtn"
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        minWidth: "auto",
                        padding: 0,
                      }}
                    >
                      <VisibilityIcon />
                    </Button>
                  </Box>
                ))}
              </Box>
            </GridItem>
          ))}
        </GridContainer>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">Note:</Typography>
          <Typography>
            If you want to use the default AWB setting, then please change the
            setting
            <Link sx={{ cursor: "pointer" }} onClick={handleClick}>
              {" "}
              here and uncheck "Ask every time".
            </Link>
          </Typography>
        </Box>
      </ModalComponent>
    </>
  );
};

export default AddBillingModal;
