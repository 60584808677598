import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import navLogo from "../../assets/images/header/navLogo.png";
import useWindowDimensions from "../../utilities/customHooks/useWindowDimensions";
import SideNavBar from "../sideNavBar";
import TopNavBar, { languagesOptions } from "../topNavBar";
// import { useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallIcon from "@mui/icons-material/Call";
import GroupsIcon from "@mui/icons-material/Groups";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsIcon from "@mui/icons-material/Settings";
import { Divider } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import mbLogo from "../../assets/images/header/mbLogo.png";
import { styleSheet } from "../../assets/styles/style";
import { getThisKeyCookie } from "../../utilities/cookies";
import { EnumRoutesUrls } from "../../utilities/enum";
import {
  purple,
  useIsUserProfileSideBarShow,
} from "../../utilities/helpers/Helpers";
import MainLoader from "../loader/mainLoader";
import {
  AccountsIcon,
  CarrierReturnsIcon,
  IntegrationIcon,
  MyCarrierIcon,
  OrderIcon,
  ProductIcon,
  SettingIcon,
  ShipmentIcon,
  StoreIcon,
} from "../sideNavBar/icons";
import UnauthorizedPage from "../unAuthorised";
const drawerWidth = 256;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: "0px !important",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
    background: "white",
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const MuiIcon = ({ isSelected, icon }) => {
  return React.cloneElement(icon, {
    sx: {
      color: isSelected ? "#563AD5" : "#1E1E1E",
    },
  });
};

function PrivateRoutes({ children, type, ...rest }) {
  const { window } = rest;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [pcOpen, setPcOpen] = React.useState(true);
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xl"));

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const isUserProfileSideBarShow = useIsUserProfileSideBarShow();

  const userProfileSideBarMenu = [
    {
      isCollapse: false,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.PROFILE_TEXT,
        path: EnumRoutesUrls.PROFILE,
      },
      icon: <MuiIcon icon={<AccountCircleIcon />} />,
    },
    {
      isCollapse: true,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.SECURITY_TEXT,
        path: EnumRoutesUrls.PASSWORD,
      },
      subTabData: [
        {
          sideTitle: LanguageReducer?.languageType?.PASSWORD_TEXT,
          topTitle: LanguageReducer?.languageType?.SECURITY_TEXT,
          path: EnumRoutesUrls.PASSWORD,
        },
      ],
      otherRoutes: [
        // {
        //   topTitle:
        //     LanguageReducer?.languageType?.CREATE_FULFILLABLE_ORDER_TEXT,
        //   path: "/create-fulfillable-order",
        // },
      ],
      icon: <MuiIcon icon={<SecurityIcon />} />,
    },
    {
      isCollapse: false,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.USERS_TEXT,
        topTitle: LanguageReducer?.languageType?.USER_S_TEXT,
        path: EnumRoutesUrls.USERS,
      },
      icon: <MuiIcon icon={<GroupsIcon />} />,
    },
    {
      isCollapse: true,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.SETTINGS,
        topTitle: LanguageReducer?.languageType?.DRIVER_STATUSE_S__TEXT,
        path: EnumRoutesUrls.DRIVER_STATUSES,
      },
      subTabData: [
        {
          sideTitle: LanguageReducer?.languageType?.DRIVER_STATUSES_TEXT,
          topTitle: LanguageReducer?.languageType?.DRIVER_STATUSE_S__TEXT,
          path: EnumRoutesUrls.DRIVER_STATUSES,
        },
      ],
      otherRoutes: [
        // {
        //   topTitle:
        //     LanguageReducer?.languageType?.CREATE_FULFILLABLE_ORDER_TEXT,
        //   path: "/create-fulfillable-order",
        // },
      ],
      icon: <MuiIcon icon={<SettingsIcon />} />,
    },
    {
      isCollapse: false,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.CONTACT_US_TEXT,
        path: EnumRoutesUrls.CONSTACT_US,
      },
      icon: <MuiIcon icon={<CallIcon />} />,
    },
  ];

  const navBarMenu = [
    {
      isCollapse: false,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.ORDERS_TEXT,
        topTitle: LanguageReducer?.languageType?.ORDER_DASHBOARD_TEXT,
        path: "/orders-dashboard",
      },
      subTabData: [
        {
          sideTitle: LanguageReducer?.languageType?.SALE_CHANNEL_ORDERS_TEXT,
          topTitle: LanguageReducer?.languageType?.SALE_CHANNEL_ORDERS_S_TEXT,
          path: "/sale-channel-orders",
        },
      ],
      otherRoutes: [
        {
          topTitle:
            LanguageReducer?.languageType?.CREATE_FULFILLABLE_ORDER_TEXT,
          path: "/create-fulfillable-order",
        },
        {
          topTitle: LanguageReducer?.languageType?.CREATE_REGULAR_ORDER_TEXT,
          path: "/create-regular-order",
        },
        {
          topTitle: LanguageReducer?.languageType?.UPLOAD_ORDER_S,
          path: "/upload-orders",
        },
        {
          topTitle: "Update Regular Order",
          path: "/edit-order-regular",
        },
        {
          topTitle: "Update Fulfillable Order",
          path: "/edit-order-fulfillable",
        },
      ],
      icon: <OrderIcon />,
    },
    {
      isCollapse: true,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.PRODUCTS_TEXT,
        topTitle: LanguageReducer?.languageType?.PRODUCT_S_TEXT,
        path: "/products",
      },
      subTabData: [
        {
          sideTitle: LanguageReducer?.languageType?.INVENTORY_TEXT,
          topTitle: LanguageReducer?.languageType?.INVENTORY_S_TEXT,
          path: "/inventory",
        },
        // {
        //   sideTitle: "Low Inventory",
        //   path: "/low-inventory",
        // },
        // {
        //   sideTitle: LanguageReducer?.languageType?.INVENTORY_SALES_TEXT,
        //   topTitle: LanguageReducer?.languageType?.INVENTORY_SALE_S_TEXT,
        //   path: "/inventory-sales",
        // },
        // {
        //   sideTitle: LanguageReducer?.languageType?.SALE_CHANNEL_PRODUCT_TEXT,
        //   topTitle: LanguageReducer?.languageType?.SALE_CHANNEL_PRODUCT_S_TEXT,
        //   path: "/sale-channel-product",
        // },
        // {
        //   sideTitle: LanguageReducer?.languageType?.PRODUCT_STATIONS_TEXT,
        //   topTitle: LanguageReducer?.languageType?.PRODUCT_STATION_S_TEXT,
        //   path: "/product-station",
        // },
      ],
      otherRoutes: [
        {
          topTitle: LanguageReducer?.languageType?.ADD_PRODUCTS_TEXT,
          path: "/add-products",
        },
        {
          topTitle: "Edit Product",
          path: "/edit-products",
        },
      ],
      icon: <ProductIcon />,
    },
    {
      isCollapse: false,
      tabData: {
        sideTitle: LanguageReducer?.languageType?.SHIPMENTS_TEXT,
        topTitle: LanguageReducer?.languageType?.SHIPMENT_S_TEXT,
        path: "/shipments",
      },

      icon: <ShipmentIcon />,
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerOpenPc = () => {
    setPcOpen(true);
  };

  React.useEffect(() => {
    if (matches) {
      setPcOpen(false);
    }
  }, [matches]);

  const handleDrawerClosePc = () => {
    setPcOpen(false);
  };

  const adminAuth = {
    isLoading: false,
    isPermitted: true,
  }; /* useSelector((state) => state.adminAuth); */
  const container =
    window !== undefined ? () => window().document.body : undefined;
  let access_token = getThisKeyCookie("access_token");
  const languageDirection =
    languagesOptions.find((lang) => lang.name === localStorage.language)?.dir ||
    "ltr";

  if (access_token) {
    return (
      <Box
        sx={{
          ...styleSheet["@global"],
          display: "flex",
          flexDirection: languageDirection === "ltr" ? "row" : "row-reverse",
        }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          color="default"
          enableColorOnDark
          evaluation={0}
          sx={{
            width: { sm: `calc(100% - ${pcOpen ? drawerWidth : 60}px)` },
            ml: {
              sm:
                languageDirection === "ltr"
                  ? `${pcOpen ? drawerWidth : 60}px`
                  : "",
            },
            boxShadow: "none",
            zIndex: 5,
            px: 1.25,
            right: languageDirection === "ltr" ? 0 : "",
            left: languageDirection === "ltr" ? "" : 0,
          }}
        >
          <Toolbar sx={{ p: "0px !important" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                marginLeft: "2px!important",
                pr: 0.5,
                pl: 0,
                display: { sm: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>
            <TopNavBar
              navBarMenu={
                isUserProfileSideBarShow ? userProfileSideBarMenu : navBarMenu
              }
            />
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{
            width: { sm: pcOpen ? drawerWidth : 60 },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* <SideNavBar /> */}
          <Drawer
            anchor={languageDirection === "ltr" ? "left" : "right"}
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                overflow: "visible",
                width: drawerWidth,
                zIndex: "1000",
                background: "#EEEEEE",
              },
            }}
          >
            <DrawerHeader>
              <Toolbar
                sx={{
                  justifyContent: "flex-start !important",
                  paddingLeft: "4px !important",
                }}
              >
                <img
                  style={{
                    width: "140px",
                    marginTop: "10px",
                  }}
                  width
                  src={navLogo}
                  alt="Nav logo"
                />
              </Toolbar>
              <Divider />
            </DrawerHeader>
            <SideNavBar
              mobileOpen={mobileOpen}
              navBarMenu={
                isUserProfileSideBarShow ? userProfileSideBarMenu : navBarMenu
              }
            />
          </Drawer>
          <Drawer
            anchor={languageDirection === "ltr" ? "left" : "right"}
            variant="permanent"
            open={pcOpen}
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: pcOpen ? drawerWidth : 60,
                boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
                filter:
                  "drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12))",
                borderRight: "none",
                zIndex: "1000",
                overflow: "visible",
              },
            }}
          >
            <DrawerHeader
              style={{
                paddingRight: "17px",
                background: "#EEEEEE",
                height: "100px",
                justifyContent: "start",
              }}
            >
              <Toolbar
                style={{
                  background: "#EEEEEE",
                  padding: 0,
                }}
              >
                {pcOpen || width < 600 ? (
                  <img
                    style={{ width: "140px", marginRight: "-10px" }}
                    src={navLogo}
                    alt="Nav logo"
                  />
                ) : (
                  <img
                    onClick={handleDrawerOpenPc}
                    style={{ width: "40px", cursor: "pointer", ml: "5px" }}
                    src={mbLogo}
                    alt="Nav logo"
                  />
                )}
              </Toolbar>
              {pcOpen ? (
                <IconButton
                  sx={{
                    background: "white",
                    padding: "0px",
                    border: "1px solid white",
                    position: "absolute",
                    right: languageDirection === "ltr" ? "-11px" : "240px",
                    top: 47,
                    "&:hover": {
                      border: `1px solid ${purple}`,
                      background: "rgb(86, 58, 213, .2)",
                    },
                  }}
                  onClick={handleDrawerClosePc}
                >
                  {languageDirection === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon
                      sx={{
                        fontSize: "22px",
                        "&:hover": {
                          color: purple,
                        },
                      }}
                    />
                  )}
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    background: "white",
                    padding: "0px",
                    border: "1px solid white",
                    position: "absolute",
                    top: "52px",
                    right: languageDirection === "ltr" ? "-13px" : "46px",
                    "&:hover": {
                      border: `1px solid ${purple}`,
                      background: "rgb(86, 58, 213, .2)",
                    },
                  }}
                  onClick={handleDrawerOpenPc}
                >
                  <ChevronRightIcon
                    sx={{
                      fontSize: "19px",
                      "&:hover": {
                        color: purple,
                      },
                    }}
                  />
                </IconButton>
              )}
            </DrawerHeader>
            <SideNavBar
              pcOpen={pcOpen}
              navBarMenu={
                isUserProfileSideBarShow ? userProfileSideBarMenu : navBarMenu
              }
            />
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            position: "relative",
            width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
            backgroundColor: "white",
            /*minHeight: "100vh",*/
            padding: "0 0 !important",
            paddingLeft: "0px",
            paddingRight: "0px",
            marginTop: "64px",
          }}
        >
          {adminAuth.isLoading ? (
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Box
                sx={{
                  p: 8,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* Loading Please wait... */}
              </Box>
            </div>
          ) : adminAuth.isPermitted ? (
            children
          ) : (
            <UnauthorizedPage />
          )}
        </Box>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={adminAuth.isLoading}
        >
          <MainLoader />
        </Backdrop>
      </Box>
    );
  } else {
    return <Navigate to={`/login`} />;
  }
}

PrivateRoutes.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PrivateRoutes;
