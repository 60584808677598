import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Login } from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import { handleDispatchUserProfile } from "../../../components/topNavBar";
import { updateUserRole } from "../../../redux/userRole";
import UtilityClass from "../../../utilities/UtilityClass";
import { getThisKeyCookie } from "../../../utilities/cookies";
import { setUserCredential } from "../../../utilities/cookies/setCookie";
import { EnumUserType } from "../../../utilities/enum";
import {
  cookie_isUserProfileSideBarShow,
  placeholders,
} from "../../../utilities/helpers/Helpers";
import { errorNotification } from "../../../utilities/toast";
import setLoginCookies from "./setLoginCookies";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function LoginPage(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control,
  } = useForm();
  useEffect(() => {
    console.log(
      "getThisKeyCookie(access_token)",
      getThisKeyCookie("access_token")
    );
    if (getThisKeyCookie("access_token")) {
      handleDispatchUserProfile(dispatch, false, navigate);
      navigate("/orders-dashboard");
    }
  }, []);
  const loginForm = async (data) => {
    setIsLoading(true);
    console.log("values", values);
    const body = {
      userName: data.userName,
      password: data.password,
    };
    Login(body)
      .then((res) => {
        if (!res.data.isSuccess) {
          if (
            res?.data?.errors?.UsernameNotConfirm &&
            res?.data?.errors?.UsernameNotConfirm[0]
          ) {
            let uData = UtilityClass.encryptData(body);
            setUserCredential(uData);
            const timer = setTimeout(() => {
              navigate("/verify-otp", {
                state: { userName: body.userName },
              });
            }, 2000);
            return () => clearTimeout(timer);
          } else {
            // errorNotification(
            //   res?.data?.errors?.InvalidUserNamePassword?.length
            //     ? res?.data?.errors?.InvalidUserNamePassword[0]
            //     : null
            // );
            // errorNotification(
            //   res?.data?.errors?.UserNotFound?.length
            //     ? res?.data?.errors?.UserNotFound[0]
            //     : null
            // );

            UtilityClass.showErrorNotificationWithDictionary(res?.data?.errors);

            // errorNotification(
            //   LanguageReducer?.languageType?.INVALID_USER_NAME_PASSWORD_TOAST
            // );

            // ------
          }
          setIsLoading(false);
        } else {
          if (
            res?.data?.result?.userRoleId &&
            res?.data?.result?.userRoleId != EnumUserType.Driver
          ) {
            dispatch(updateUserRole(res?.data?.result?.userRoleId));
            setLoginCookies(res);
            setIsLoading(false);
            navigate("/orders-dashboard");
            if (cookie_isUserProfileSideBarShow) {
              handleDispatchUserProfile(dispatch, false, navigate);
            }
          } else {
            errorNotification("You are not authorized");
          }
        }
      })
      .catch((e) => {
        errorNotification(
          LanguageReducer?.languageType?.INVALID_USER_NAME_PASSWORD_TOAST
        );
        setIsLoading(false);
        console.log("e", e);
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleFocus = (event) => event.target.select();
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        margin: "auto",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "25px",
        p: { md: "10px", sm: "15px", xs: "20px" },
      }}
    >
      <Paper
        elevation={6}
        TransitionComponent={Transition}
        sx={{ ...styleSheet.cardMainClass, width: "100%" }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ ...styleSheet.outScanHeading, width: "100%" }}>
          {LanguageReducer?.languageType?.LOGIN_TEXT}
        </DialogTitle>
        <form onSubmit={handleSubmit(loginForm)} width={"100%"}>
          <DialogContent sx={{ ...styleSheet.cardContentArea, width: "100%" }}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.USER_NAME_TEXT}
            </InputLabel>
            <TextField
              placeholder={placeholders.name}
              onFocus={handleFocus}
              type="text"
              size="small"
              id="userName"
              name="userName"
              fullWidth
              variant="outlined"
              {...register("userName", {
                required: {
                  value: true,
                  message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                },
                pattern: {
                  message:
                    LanguageReducer?.languageType
                      ?.INPUT_SHOULD_NOT_CONTAIN_SPACES_OR_UPPERCASE,
                },
              })}
              error={Boolean(errors.userName)} // set error prop
              helperText={errors.userName?.message}
            />
            <br />
            <br />
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.PASSWORD_TEXT}
            </InputLabel>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                placeholder={"●●●●●●●●●"}
                onFocus={handleFocus}
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={(e) =>
                  setValues({ ...values, password: e.target.value })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                size="small"
                fullWidth
                id="password"
                name="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                })}
                error={Boolean(errors.password)} // set error prop
                helperText={errors.password?.message}
              />
            </FormControl>
            <Typography sx={styleSheet.forgotPasswordText}>
              <Link to="/forgot-password">
                {LanguageReducer?.languageType?.FORGOT_PASSWORD_TEXT}?{" "}
              </Link>
            </Typography>
          </DialogContent>
          <DialogActions>
            {isLoading ? (
              <Button
                fullWidth
                variant="contained"
                sx={styleSheet.modalSubmitButton}
                disabled
              >
                <CircularProgress sx={{ color: "white" }} />
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={styleSheet.modalSubmitButton}
                type="submit"
              >
                {LanguageReducer?.languageType?.LOGIN_TEXT}
              </Button>
            )}
          </DialogActions>
        </form>
      </Paper>
    </Box>
  );
}
export default LoginPage;
